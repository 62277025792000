"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const react_final_form_1 = require("react-final-form");
const utils_1 = require("utils");
const internal_portal_components_1 = require("internal-portal-components");
const react_redux_1 = require("react-redux");
const react_final_form_listeners_1 = require("react-final-form-listeners");
function GlobalSearchForm({ handleSubmit, loading }) {
    const searchValue = (0, react_redux_1.useSelector)((state) => state.root.internalPortal.globalSearch.searchValue);
    const searchCriteria = (0, react_redux_1.useSelector)((state) => state.root.internalPortal.globalSearch.searchCriteria);
    const validateAndParseMap = {
        'Phone Number': {
            validate: (0, utils_1.composeValidators)(utils_1.validateRequiredField, (0, utils_1.validateLength)(14, 14, 'Expected format should be (XXX) XXX-XXXX')),
            parse: utils_1.parsePhoneNumber,
            submitValue: (value) => value.replace(/[^\d]/g, ''),
        },
        'Account Number': {
            validate: (0, utils_1.composeValidators)(utils_1.validateRequiredField, (0, utils_1.validateLength)(1, 16, 'Length must be 16 characters or less')),
            parse: utils_1.parseAccountNumber,
            submitValue: (value) => value,
        },
    };
    const onSubmit = (values) => {
        handleSubmit({
            searchValue: validateAndParseMap[values.searchCriteria].submitValue(values.searchValue),
            searchCriteria: values.searchCriteria,
        });
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_final_form_1.Form, { onSubmit: onSubmit, initialValues: {
                    searchValue: searchValue
                        ? validateAndParseMap[searchCriteria].parse(searchValue)
                        : '',
                    searchCriteria,
                }, render: ({ handleSubmit, hasValidationErrors, form }) => ((0, jsx_runtime_1.jsxs)("form", { onSubmit: handleSubmit, className: "global-search-input-container", children: [(0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: "searchCriteria", children: ({ input }) => ((0, jsx_runtime_1.jsx)(react_1.CFormSelect, { ...input, "data-testid": "global-search-criteria-select", options: [
                                    {
                                        value: 'Phone Number',
                                        label: 'Phone Number',
                                    },
                                    {
                                        value: 'Account Number',
                                        label: 'Account Number',
                                    },
                                ] })) }), (0, jsx_runtime_1.jsx)(react_final_form_listeners_1.OnChange, { name: "searchCriteria", children: () => {
                                form.change('searchValue', '');
                            } }), (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: "searchValue", validate: validateAndParseMap[form.getState().values.searchCriteria]
                                .validate, parse: validateAndParseMap[form.getState().values.searchCriteria].parse, children: ({ input, meta }) => ((0, jsx_runtime_1.jsxs)("div", { className: "input-and-label", children: [(0, jsx_runtime_1.jsx)(react_1.CFormInput, { ...input, className: meta.error && meta.touched ? 'invalid' : '', placeholder: form.getState().values.searchCriteria === 'Phone Number'
                                            ? '(XXX) XXX-XXXX'
                                            : 'Account Number', "data-testid": "global-search-input" }), meta.error && meta.touched ? ((0, jsx_runtime_1.jsx)("span", { className: "error", children: meta.error })) : ((0, jsx_runtime_1.jsx)("span", { className: "error" }))] })) }), (0, jsx_runtime_1.jsx)(internal_portal_components_1.SubmitButton, { color: "primary", disabled: hasValidationErrors || loading, loading: loading, dataTestID: "global-search-submit-button", children: "Search" })] })) }), searchCriteria === 'Account Number' && ((0, jsx_runtime_1.jsx)(react_1.CAlert, { color: "warning", children: "Account Number does not yet support the SMS and Email tables." }))] }));
}
exports.default = GlobalSearchForm;
