"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePostBlacklistedAccountMutation = exports.useGetReceiptsForTransactionQuery = exports.useGetBankNameQuery = exports.useGetAccountDetailsQuery = exports.api = void 0;
const react_1 = require("@reduxjs/toolkit/query/react");
const config_1 = require("config");
const lodash_1 = require("lodash");
const utils_1 = require("utils");
const baseQuery = (0, react_1.fetchBaseQuery)({
    baseUrl: config_1.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
        const accessToken = await utils_1.manageAuth0AccessToken.getAccessTokenSilently()();
        headers.set('Authorization', process.env.NODE_ENV === 'test' ? '' : `Bearer ${accessToken}`);
    },
});
const baseQueryWithCamelize = (0, utils_1.camelizeResponse)(baseQuery);
exports.api = (0, react_1.createApi)({
    reducerPath: 'merchantPortalApi',
    baseQuery: baseQueryWithCamelize,
    endpoints: (builder) => ({
        getAccountDetails: builder.query({
            query: ({ alphaeonAccountNumber, locationId, buyerId }) => {
                return {
                    url: `/merchant_management/v1/lookups/accounts/${alphaeonAccountNumber}`,
                    params: (0, lodash_1.isNil)(buyerId) || buyerId === 0 || buyerId === ''
                        ? { location_id: locationId }
                        : { location_id: locationId, buyer_id: buyerId },
                };
            },
        }),
        getReceiptsForTransaction: builder.query({
            query: ({ transactionId }) => {
                return {
                    url: `/merchant_management/v2/transactions/${transactionId}/receipts`,
                };
            },
        }),
        getBankName: builder.query({
            query: ({ routingNumber }) => {
                return {
                    url: '/merchant_management/v1/bank_lookup',
                    params: {
                        routing_number: routingNumber,
                    },
                };
            },
        }),
        postBlacklistedAccount: builder.mutation({
            query: (body) => {
                return {
                    url: `/merchant_management/v1/accounts/ads/${body.accountId}/blacklisted_account`,
                    method: 'POST',
                    body: {
                        auth_buyers: body.authBuyers,
                        reasons_for_verification: body.reasonsForVerification,
                    },
                };
            },
        }),
    }),
    keepUnusedDataFor: 0,
});
exports.useGetAccountDetailsQuery = exports.api.useGetAccountDetailsQuery, exports.useGetBankNameQuery = exports.api.useGetBankNameQuery, exports.useGetReceiptsForTransactionQuery = exports.api.useGetReceiptsForTransactionQuery, exports.usePostBlacklistedAccountMutation = exports.api.usePostBlacklistedAccountMutation;
