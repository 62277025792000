"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducerKey = exports.selectors = exports.reducer = void 0;
const redux_actions_1 = require("redux-actions");
const reselect_1 = require("reselect");
const fp_1 = require("lodash/fp");
const lp_redux_utils_1 = require("lp-redux-utils");
const lodash_1 = require("lodash");
const lp_redux_api_1 = require("lp-redux-api");
const redux_1 = require("redux");
const local_storage_1 = require("local-storage");
const reducer_1 = require("./account_lookup/reducer");
const reducer_2 = require("./site-initialization/reducer");
const reducer_3 = require("./program-management/reducer");
const reducer_4 = require("./reports/reducer");
const reducer_5 = require("./sales/reducer");
const reducer_6 = require("./user/reducer");
const actions = __importStar(require("./actions"));
const apiActions = __importStar(require("api-actions"));
const reducerKey = 'merchantPortal';
exports.reducerKey = reducerKey;
const slice = 'root.merchantPortal.root';
const initialState = {
    isLoggedIn: true,
    userToken: 'xxx',
    currentLocationIndex: (0, local_storage_1.getLocation)()?.value || 0,
};
const merchantPortalReducer = (0, redux_actions_1.handleActions)({
    [actions.clearMerchantApplicationEnvironment.toString()]: (0, lp_redux_utils_1.unsetState)('merchantApplicationEnvironment'),
    [actions.setApplicationInfo.toString()]: (0, lp_redux_utils_1.setState)('fullApplicationInfo'),
    [actions.clearAllLocations.toString()]: (0, lp_redux_utils_1.unsetState)('allLocations'),
    [actions.setCurrentLocationIndex.toString()]: (0, lp_redux_utils_1.setState)('currentLocationIndex'),
    [actions.setLocationNotificationsDismissed.toString()]: (0, lp_redux_utils_1.setState)('locationNotificationsDismissed'),
    [actions.setLenderReferenceId.toString()]: (0, lp_redux_utils_1.setState)('lenderReferenceId'),
    [apiActions.newMerchantCreditApplication]: (0, lp_redux_api_1.setOnSuccess)('merchantApplicationEnvironment'),
    [apiActions.applyForCredit]: (0, lp_redux_api_1.setOnSuccess)('approvedCreditApplication'),
    [apiActions.fetchAllLocationsForManager]: (0, lp_redux_api_1.handleSuccess)((state, { payload: { data: locations } }) => (0, fp_1.set)('allLocations', locations.sort((locationA, locationB) => {
        const storeNumberA = locationA.adsStoreNumber;
        const storeNumberB = locationB.adsStoreNumber;
        // While nil store number values aren't expected, handle them anyway
        // and place them at the END of the location list...
        if ((0, lodash_1.isNil)(storeNumberA))
            return 1;
        if ((0, lodash_1.isNil)(storeNumberB))
            return -1;
        return storeNumberA - storeNumberB;
    }), state)),
    [apiActions.fetchLocationDetails]: (0, lp_redux_api_1.setOnSuccess)('currentLocation'),
    [apiActions.refundRequest]: (0, lp_redux_api_1.setOnSuccess)('refundedTransaction'),
    [apiActions.refundLoan]: (0, lp_redux_api_1.setOnSuccess)('refundedTransaction'),
    [apiActions.fetchTransactions]: (0, lp_redux_api_1.setOnSuccess)('transactions'),
    [apiActions.fetchApplicationActivity]: (0, lp_redux_api_1.setOnSuccess)('applicationActivity'),
    [apiActions.getApplicationsReport]: (0, lp_redux_api_1.setOnSuccess)('applicationReports'),
    [apiActions.getSalesReport]: (0, lp_redux_api_1.setOnSuccess)('salesReports'),
    [apiActions.getLenderSettings.toString()]: (0, lp_redux_api_1.setOnSuccess)('lenderSettings'),
    [apiActions.getCreditAvailableReport]: (0, lp_redux_api_1.setOnSuccess)('creditAvailableReport'),
    [apiActions.getLocationNotifications]: (0, lp_redux_api_1.setOnSuccess)('locationNotifications'),
    [apiActions.getGlobalConfig]: (0, lp_redux_api_1.setOnSuccess)('globalConfig'),
}, initialState);
const merchantRootReducer = (0, redux_1.combineReducers)({
    ['root']: merchantPortalReducer,
    [reducer_1.reducerKey]: reducer_1.reducer,
    [reducer_2.reducerKey]: reducer_2.reducer,
    [reducer_3.reducerKey]: reducer_3.reducer,
    [reducer_4.reducerKey]: reducer_4.reducer,
    [reducer_5.reducerKey]: reducer_5.reducer,
    [reducer_6.reducerKey]: reducer_6.reducer,
});
function reducer(state, action) {
    if (action.type === actions.clearMerchantPortal.toString()) {
        return merchantRootReducer(undefined, action);
    }
    return merchantRootReducer(state, action);
}
exports.reducer = reducer;
const select = (0, lp_redux_utils_1.selectorForSlice)(slice);
const selectors = {
    allLocations: select('allLocations'),
    applicationInfo: select('fullApplicationInfo'),
    applicationReports: select('applicationReports'),
    approvedCreditApplication: select('approvedCreditApplication'),
    currentLocationIndex: select('currentLocationIndex'),
    isLoggedIn: select('isLoggedIn'),
    merchantApplicationEnvironment: select('merchantApplicationEnvironment'),
    refundedTransaction: select('refundedTransaction'),
    salesReports: select('salesReports'),
    creditAvailableReport: select('creditAvailableReport'),
    transactions: select('transactions'),
    locationNotifications: select('locationNotifications'),
    locationNotificationsDismissed: select('locationNotificationsDismissed'),
    lenderReferenceId: select('lenderReferenceId'),
    currentLocation: select('currentLocation'),
    globalConfig: select('globalConfig'),
    lenderSettings: select('lenderSettings'),
};
exports.selectors = selectors;
selectors.currentPermissions = (0, reselect_1.createSelector)([selectors.currentLocation], (locationDetails) => {
    return locationDetails ? locationDetails['permissions'] : {};
});
